import React, { useState, useEffect, Fragment } from "react";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";

import { useSelector } from "react-redux";

export function AsideMenuMain() {
  const stateMenus = useSelector((state: any) => state.menus.menus);

  return (
    <>
      {stateMenus && stateMenus.length
        ? stateMenus.map((menu: any) => {
            if (menu.mostrar_menu)
              return (
                <Fragment key={menu.id}>
                  {menu.children ? (
                    <AsideMenuItemWithSub menu={menu} />
                  ) : (
                    <AsideMenuItem menu={menu} />
                  )}
                </Fragment>
              );
          })
        : null}
    </>
  );
}
