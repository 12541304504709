import { MenuItem } from "./MenuItem";
import { useIntl } from "react-intl";

export function MenuInner() {
  const intl = useIntl();
  return (
    <>
      <MenuItem
        title={intl.formatMessage({ id: "HOME.APLICACOES" })}
        to="/home"
      />
    </>
  );
}
