import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const menusSlice = createSlice({
  name: "menus",
  initialState: {
    active: null,
    menus: null,
  },
  reducers: {
    save(state: any, action: PayloadAction<any>) {
      state.menus = action.payload.menus;
    },

    delete(state: any) {
      state.menus = null;
    },

    updateActiveMenu(state: any, action: PayloadAction<any>) {
      state.active = action.payload.menuId;
    },
  },
});

export const menusActions = menusSlice.actions;

export default menusSlice;
